table {
  @extend .table;
  margin: 20px 0 40px 0;
  border:none;
  > tbody {
    > tr {
      > td {
        border-top: 1px solid $black;
        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
}


table > tbody > tr > td {
  border-bottom: 1px solid $black;
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 0.8em;
}


@media all and (min-width: $screen-lg-min) {



  table > tbody > tr > td {
    font-size: 1em;
  }

}
