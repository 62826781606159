
.blog-article-shortform,
.blog-article-longform {
  padding-bottom: 5em;
  &:last-child {
    padding-bottom: 0;
  }
  .blog-txt {
    padding-bottom: 12px;
  }
  .blog-img > .btn {
    margin-top: 30px;
  }
}

.blog-article-longform {
  .blog-img > .btn {
    margin-top: 0;
  }
}
