.full-link {
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  bottom: 0;
}

.home-teaser-content {
  position: relative;
  .overlay {
    left: 0;
    right: 0;
    padding: 0;
  }
  .home-teaser-inside {
    position: absolute;
    bottom: 25px;
    left: 20px;
    right: 20px;
    top: auto;
    p {
      margin-bottom: 1em;
      text-transform: uppercase;
      color: $white;
      line-height: 1.1;
      text-shadow: 0 1px 4px rgba(0,0,0,0.5);
    }
  }
}


.wide-item-content {
  position: relative;
  padding: 20px 15px 25px 15px;
  text-transform: uppercase;
  transition: all .2s ease-in-out;
  p,h1 {
    margin: 0;
  }
  i {
    font-style: normal;
  }
  p {
    line-height: 1.3;
    margin: 10px 0;
  }
  &.black {
    background-color: $black;
    color: $white;
    h1 {
      font-weight: 700;
      text-transform: none;
      margin-bottom: 0.7em;
      i {
        border-bottom: 5px solid $white;
      }
    }
    &:hover {
      background-color: lighten($black, 20%);
    }
  }
  img {
    width: unset;
    margin: 0.2em 0 0.6em 0;
    display: inline-block;
  }
}

.wide-item-content.black .btn-default {
  border: 1px solid $white;
  background-color: $white;
  color: $black;
  vertical-align: 0.4em;
  display: block;
  margin: 0.5em 0;
  width: max-content;
  white-space: nowrap;
  &:hover, &:focus, &:active, &:active:focus {
    border: 1px solid $white;
    background-color: $black;
    color: $white;
  }
}


@media all and (min-width: $screen-md-min) {

  .home-teaser-content {
    .home-teaser-inside {
      right: 40px;
      bottom: 40px;
      p {
        font-size: 30px;
      }
    }
  }


  .wide-item-content {
    font-size: 30px;
    padding: 30px 40px 35px 35px;
    &.black {
      h1 {
        margin-bottom: 1em;
        font-size: 50px;
        line-height: 1.1;
        i {
          border-bottom: 9px solid $white;
        }
      }
    }
  }

}

@media all and (min-width: $screen-lg-min) {
  .wide-item-content.black .btn-default {
  	vertical-align: 0.25em;
  	display: inline;
  	margin: 0;
  	width: auto;
  }
}


@media all and (min-width: 1420px) {

  .wide-item-content {
    font-size: 36px;
    padding: 40px 50px 45px 45px;
    &.black {
      h1 {
        font-size: 60px;
        i {
          border-bottom: 10px solid $white;
        }
      }
    }
  }

}
