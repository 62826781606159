* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

/* force scrollbar, prevents initial gap */
html {
  overflow-y: scroll;
}
/* Isotope Transitions
------------------------------- */
.isotope,
.isotope .item {
  -webkit-transition-duration: 0.5s;
     -moz-transition-duration: 0.5s;
      -ms-transition-duration: 0.5s;
       -o-transition-duration: 0.5s;
          transition-duration: 0.5s;
}

.isotope {
  -webkit-transition-property: height, width;
     -moz-transition-property: height, width;
      -ms-transition-property: height, width;
       -o-transition-property: height, width;
          transition-property: height, width;
}

.isotope .item {
  -webkit-transition-property: -webkit-transform, opacity;
     -moz-transition-property:    -moz-transform, opacity;
      -ms-transition-property:     -ms-transform, opacity;
       -o-transition-property:         top, left, opacity;
          transition-property:         transform, opacity;
}
/* ---- grid ---- */

.grid {

}

/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

.grid-item,
.grid-sizer {
  width: 100%;
}

/* ---- .grid-item ---- */

.grid-item {
  margin-bottom: 10px;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 14px 17px;
  -webkit-transition: all .25s;
  -moz-transition: all .25s;
  transition: all .25s;
  overflow: hidden;
  opacity: 1;
  color: white;
  background: rgba(0, 0, 0, 0);
  .btn {
    border: none !important;
    font-size: 11px;
  }
}

.grid-item img {
  display: block;/* Prevent inline gap under image*/
  width: 100%;
}

@media all and (min-width: 600px) {

  .grid-item,
  .grid-sizer {
    width: 50%;
  }

  .grid-item {
    padding: 0 5px;
  }

  .overlay {
    left: 5px;
    right: 5px;
  }

}





@media all and (min-width: $screen-md-min) {

  .grid-item,
  .grid-sizer {
    width: 33%;
  }

  .grid-item-wide {
    width: 66%;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: all .25s;
    -moz-transition: all .25s;
    transition: all .25s;
    overflow: hidden;
    opacity: 0;
    color: white;
    background: rgba(0, 0, 0, 0.3);
    &:hover {
      opacity: 1;
    }
    .btn {
      font-size: 16px;
    }
  }

}

@media all and (min-width: $screen-lg-min) {

  .grid-item {
    padding: 0 15px;
    margin-bottom: 30px;
  }

  .overlay {
    left: 15px;
    right: 15px;
  }
}
