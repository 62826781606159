blockquote.withimage {
  background-color: #f4f5f4;
  border: none;
  padding: 50px 30px 130px 30px;
  border-radius: 2px;
  position: relative;
  cite {
    text-align: center;
  }
  .quote-image {
    position: absolute;
    width: 216px;
    bottom: -108px;
    left: 50%;
    margin-left: -108px;
    border: 8px solid white;
  }
}

.quoteicon {
  background: black;
  color: white;
  font-size: 30px;
  border-radius: 50%;
  text-align: center;
  width: 60px;
  height: 60px;
  font-size: 80px;
  line-height: 5px;
  padding-left: 2px;
  position: absolute;
  left: 50%;
  margin-left: -30px; top: -30px;
}
