footer {
	.footer-top {
		padding-top: 50px;
		background-color: $lightGray;
		.footer-service-nav {
			padding-bottom: 30px;
			ul {
				list-style: none;
				margin: 0;
				padding-left: 0;
				li {
					margin-bottom: 5px;
					a {
						padding: 5px 0;
						display: inline-block;
						text-transform: uppercase;
						letter-spacing: .15em;
					}
					a:link,
					a:visited,
					a:active,
					a:focus {
						color: $black;
					}
					a:hover {
						color: $blue;
					}
				}
			}
		}
		.footer-nl-info {
			padding-bottom: 15px;
			p:first-child {
				font-size: 28px;
			}
		}
		.footer-nl-subscribe {
			padding-left: 20px;
		}
	}
	.footer-bottom {
		padding: 25px 0;
		background-color: $black;
		.footer-copyright {
			p {
				color: $white;
				font-size: 14px;
				text-align: center;
				margin-bottom: 0;
				letter-spacing: .15em;
			}
		}
	}

}

.footer-nl-subscribe {
	padding-bottom: 40px;
	.form-horizontal {
		max-width: 400px;
		.form-group {
			&:last-child {
				margin-bottom: 0;
			}
			.control-label {
				display: none;
			}
			.form-control {
				border-radius: 0;
				border: none;
				//border-bottom: 3px solid $black;
				height: 48px;
				&:focus {
				    //border-color: $blue;
				    box-shadow: inset 0 1px 1px rgba(0,0,0,0.0),0 0 8px rgba(102,175,233,0);
						background-color: $black;
						color: $white;
				}
			}
			button {
				@extend .btn;
				@extend .btn-primary;
				margin-top: 10px;
				margin-bottom: 0;
			}
			label {
				display: none;

			}
			&.checkbox-inline {
				margin-top: 5px;
				label {
				display: block;
				font-weight: normal;
				font-size: 14px;
				}
			}
		}
	}
}

@media screen and (min-width: $screen-md) {


	footer {
		.footer-top {
			.footer-service-nav {
				padding-bottom: 0;
				padding-top: 30px;
			}
			.footer-nl-info {
				padding-bottom: 0;
				padding-top: 30px;
				padding-right: 20px;
			}
			.footer-nl-subscribe {
				padding-bottom: 0;
				padding-top: 30px;
				.form-horizontal {
					.btn {
						//float: right;
					}
				}
			}

		}
	}

}

@media screen and (min-width: $screen-lg) {


}
