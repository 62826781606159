.acc-wrapper {
  margin-bottom: 4em;
  border-bottom: 1px solid $black;

  .acc-item {
    margin-bottom: 2px;
  }

  .acc-btn {
    border: none;
    border-radius: 0;
    border-top: 1px solid $black;
    background-color: transparent;
    color: $black;
    width: 100%;
    text-align: left;
    padding: 6px 0;
    display: flex;
    justify-content: space-between;

    .svg-icon {
      font-size: 0.6em;
      transition: all .15s ease;
      transform: rotate(180deg);
    }

    &.collapsed .svg-icon {
      transform: rotate(0);
    }
  }

  .well {
    margin: 0;
    border-radius: 0;
    border: none;
    background-color: $white;
    padding: 15px 0;
    box-shadow: none;
  }
}