//Button
.btn {
	padding: 6px 18px;
	border-radius: 0;
	text-transform: uppercase;
	letter-spacing: .15em;
	text-decoration: none;
	font-size: 14px;
	white-space: unset;
	@include transition(0.2s all ease-in-out);
}


.btn:focus,
.btn:active:focus,
.btn-defaul:focus,
.btn-default:active:focus {
	outline: none;
	box-shadow: none;
}


.btn:hover,
.btn:active:hover,
.btn-defaul:hover,
.btn-default:active:hover {
	box-shadow: none;
	text-decoration: none;
}


.btn-default {
	border: 3px solid $black;
	background-color: $black;
	color: $white;
	&:hover, &:focus, &:active, &:active:focus {
		border: 3px solid #ccc;
		background-color: $black;
		color: $white;
	}
}

.btn-primary {
	border: 3px solid $black;
	background-color: $black;
	color: $white;
	&:hover {
		border: 3px solid lighten($black,15%);
		background-color: lighten($black,15%);
		color: $white;
	}
}



.btn--secondary {
	@extend .btn-default;
}

.update-cart {
	@extend .btn-primary;
}

.home-teaser-inside .btn-default {
	border: 3px solid $black;
	background-color: $black;
	color: $white;
	&:hover, &:focus, &:active, &:active:focus {
		border: 3px solid $white;
		background-color: $black;
		color: $white;
	}
}

.cm-btn {
	padding: .4em 1em !important;
}

@media all and (min-width: $screen-sm-min) {

	.btn {
		font-size: 16px;
	}

}
