.pagination {
    border-radius: 0;
}

.pagination > li:first-child > a, .pagination > li:first-child > span,
.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-radius: 0;
}

.pagination > li > a, .pagination > li > span, .pagination span > a, .pagination > span.current {
  border: 3px solid $black;
  padding: 6px 12px;
  line-height: 1.4;
  color: $white;
  background-color: $black;
  display: block;
}

.pagination > span {
  float: left;
  display: block;
}

.pagination > span.current {
  display: block;
}

.pagination > li > a, .pagination a, .pagination span {
  text-decoration: none !important;
  font-size: 14px;
}

@media all and (min-width: $screen-sm-min) {

  .pagination > li > a, .pagination a, .pagination span {
    font-size: 16px;
  }

}
