
main {
  padding-top: 30px;
  padding-bottom: 150px;
  a {
    text-decoration: underline;
    color: $gray-light;
    &:hover {
      text-decoration: underline;
      color: $black;
    }
  }
}

.offset-top {
	//margin-top: 140px;//bacause of sticky
}

@media screen and (min-width: $screen-sm) {

  .offset-top {
		//margin-top: 306px;//bacause of sticky
	}

}

@media screen and (min-width: $screen-md) {


}

@media screen and (min-width: $screen-lg) {


}
