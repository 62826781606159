//@font-face {
//	font-family: 'Work Sans';
//	src: url('../fonts/worksans/WorkSans-Light.woff2') format('woff2'),
//	url('../fonts/worksans/WorkSans-Light.woff') format('woff');
//	font-weight: 300;
//	font-style: normal;
//	font-display: swap;
//}
//
//@font-face {
//	font-family: 'Work Sans';
//	src: url('../fonts/worksans/WorkSans-Bold.woff2') format('woff2'),
//	url('../fonts/worksans/WorkSans-Bold.woff') format('woff');
//	font-weight: bold;
//	font-style: normal;
//	font-display: swap;
//}
//
//@font-face {
//	font-family: 'Work Sans';
//	src: url('../fonts/worksans/WorkSans-Regular.woff2') format('woff2'),
//	url('../fonts/worksans/WorkSans-Regular.woff') format('woff');
//	font-weight: normal;
//	font-style: normal;
//	font-display: swap;
//}
//
//@font-face {
//	font-family: 'Work Sans';
//	src: url('../fonts/worksans/WorkSans-SemiBold.woff2') format('woff2'),
//	url('../fonts/worksans/WorkSans-SemiBold.woff') format('woff');
//	font-weight: 600;
//	font-style: normal;
//	font-display: swap;
//}
//
//@font-face {
//	font-family: 'Work Sans';
//	src: url('../fonts/worksans/WorkSans-Black.woff2') format('woff2'),
//	url('../fonts/worksans/WorkSans-Black.woff') format('woff');
//	font-weight: 900;
//	font-style: normal;
//	font-display: swap;
//}

body {
	font-weight: $w400;
	letter-spacing: .02em;
}

a:focus {
	border: none;
	outline: none;
	background-color: transparent;
}

.text-link {
    border-bottom: none !important;
}

strong {
	font-weight: $w700;
}

p {
	margin-bottom: 15px;
}

em {
	font-style: normal;
	display: block;
	border-top: 3px solid $black;
	border-bottom: 3px solid $black;
	padding: 10px 2px;
	margin-top: 40px;
	margin-bottom: 50px;
}

h1 {
	margin-bottom: 20px;
	text-transform: uppercase;
	span {
		font-size: 0.6em;
	}
}

h2 {
	letter-spacing: .005em;
	&.extra-margin {
		margin-top: 3em;
		margin-bottom: 2em;
	}
}

h3 {
	margin-top: 25px;
	margin-bottom: 20px;
	line-height: $line-height-base;
	font-weight: $w700;
}

@media screen and (min-width: $screen-sm) {

	body {
		font-size: 20px;
	}

	h1 {
		font-size: 38px;
	}

	h2 {
		font-size: 30px;
	}

	h3 {
		font-size: 20px;
		margin-top: 30px;
	}

	p {
		margin-bottom: 20px;
	}

}

@media screen and (min-width: $screen-md) {


	h1 {
		margin-bottom: 30px;
	}

}

@media screen and (min-width: $screen-lg) {

}
