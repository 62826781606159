.babel-links {
  list-style-type: none;
  padding: 30px 0 0 0;
  margin: 0;
  line-height: 1.05;
  border-top: 2px solid #d4d4d4;
  font-size: 20px;
  li {
    float: left;
    margin-bottom: 30px;
    a {
      display: block;
      color: $black;
      padding-bottom: 2px;

      &.active {
        border-bottom: 1px solid $black;
      }
    }
    span {
      padding: 0 5px 2px 5px;
    }
  }
}

//Sprachumschaltung temporär ausschalten
.weglot-container {
    display: none !important;
}

@media screen and (min-width: $screen-sm) {

  .babel-links {
    font-size: 16px;
    position: relative;
    float: right;
    top: auto;
    right: auto;
    margin-right: 27px;
    border: none;
    padding: 0;
    li {
      margin-bottom: 0;
    }
  }

  .opaque .babel-links {
    margin-top: 27px;
  }

}
