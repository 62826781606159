.home-banner {
  background-color: $pink;
  color: $white;
  padding: 0 20px;
  max-height: 0;
  overflow: hidden;
  //opacity: 0;
  transition: all 0.3s ease-in-out;
  p {
    text-align: center;
    margin-bottom: 0;
  }
  a {
    color: $pinklink;
    font-weight: bold;
    &:hover {
      color: $black;
    }
  }

  &.expand {
    padding: 20px;
    max-height: 500px;
  }
}