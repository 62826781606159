.countdown-container {
  text-align: center;
  padding: 20px 0;
  display: flex;
  margin-bottom: 20px;
}

.countdown {
  display: inline-block;
  display: flex;
  margin: 0 auto;
  div {
    strong {
      display: block;
      padding: 12px 16px 10px 16px;
      width: 65px;
      text-align: center;
      background-color: $black;
      color: $white;
      font-size: 28px;
      margin: 0 5px 10px 5px;
      @media screen and (min-width: $screen-sm) {
        font-size: 38px;
        min-width: 85px;
        padding: 16px 20px;
      }
    }
    i {
      display: block;
      font-style: normal;
      text-align: center;
    }
  }
}

.cdmessage {
  background-color: $black;
  padding: 16px 20px;
  color: $white;
  font-weight: bold;
}