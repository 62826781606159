img {
	max-width: 100%;
	height: auto;
	display: block;
}

figure, .content-img {
	margin: 2em 0 3em 0;
	figcaption {
		color: $gray-light;
		padding-top: 5px;
		font-size: 0.8em;
	}
}

.img-border {
	border: 1px solid $gray-light;
	margin-bottom: 20px;
}

.insta-item {
	margin-bottom: 20px;
}
