main {
	ul {
		padding-left: 25px;
		margin-bottom: 20px;;
		li {
			padding-bottom: 7px;
		}
	}
}

.files {
	padding: 0;
	list-style-type: none;
	li {
		a {
			text-decoration: none;
			.svg-icon {
				font-size: 0.6em;
				vertical-align: -1px;
				.icon {
					fill: #777777;
				}
			}
			&:hover {
				text-decoration: none;
				.svg-icon {
					.icon {
						fill: $black;
					}
				}
			}
		}
	}
}

.rte ul, .rte ol {

    list-style-position: initial;
    margin-left: 20px;

}


.anchor-links-container {
	margin: 2em 0;
	border-top: 2px solid $black;
	border-bottom: 2px solid $black;
}

ul.anchor-links {
	list-style-type: none;
	padding: 0;
	margin-bottom: 2em;
	margin-left: 1.2em;
	li {
		margin: 10px 0 3px 0;
		&::before {
			content: "–";
			font-size: 0.9em;
			position: absolute;
			margin-left: -1.2em;
		}
		&.level-2 {
			margin: 0 0 8px 20px;
			padding-left: 1.2em;
			font-size: .85em;
		}
		a {
			color: $black;
			text-decoration: none;
			&:hover {
				color: $gray-light;
				text-decoration: underline;
			}
		}
	}
}
