.icon-sprites {
  display: none;
}

.svg-icon {
  display: inline-flex;
  align-self: center;
  position: relative;
  height: 1.8em;
  width: 1.8em;
}

.svg-icon svg {
  bottom: -0.1em;
  position: absolute;
  fill: $black;
  height: inherit;
  width: inherit;
  transition: all .2s ease-in-out;
  &:hover {
    fill: lighten($black,20%);
  }
}