#shopify-section-header {
  margin-top: 110px;
}


//Shopify Header deaktivieren
header.site-header {
  display: none;
}

//Mobile Menu deaktivieren
#ToggleMobileMenu {
    display: none;
}

//Sidebar ausblenden
#shopify-section-sidebar {
  display: none;
}

.page-border {
  background-color: transparent;
}

//diverse Elemente ausblenden
hr.hr--border, hr.hr--small, hr.hr--border-top {
  display: none;
}

//Product detail page

h2.description {
  font-size: 1.4em;
  margin-bottom: 10px;
  margin-top: 20px;
}

.product-single__meta {
  padding-top: 0;
}

h1.product-single__title {
  margin-top: 0;
}

.product-single__description h3 {
  margin-top: 10px;
  margin-bottom: 5px;
}

.medium-up--one-third {
    //width: 25%;
}

.currency-picker {
  margin-right: 10px;
}

.currency-picker__label {
  font-weight: $w400;
}

//Übersichtsseite
.medium-up--four-fifths {
    width: 100%;
}

.social-sharing__link {
    text-decoration: none;
}

.site-header__cart {
  //@extend .btn;
  //@extend .btn-default;
}

//Cart
.txt--emphasis {
  font-style: normal;
}

.collection-sorting__dropdown {
    font-size: 0.8em;
}


.sub-footer, .site-footer {
    font-size: 0.9em;
}

.pick_currency, .mlvedaMobileFilter .pick_currency  {
  right: -10000px !important;
}

.customer-links {
  padding-top: 9px;
  display: inline-block;
}

.btn--secondary {
    line-height: 1.9;
}

@media all and (min-width: $screen-sm-min) {

  #shopify-section-header {
    margin-top: 290px;
  }

}


@media all and (min-width: $screen-lg-min) {

#ProductThumbs {
  padding-left: 25px;
  padding-right: 25px;
}

}
