.lightgallery {
  .lightgallery-item {
    margin-bottom: 30px;
  }
}

.lg-backdrop {
  background-color: $white;
}

.lg-backdrop.in {
  opacity: 0.85;
}

.lg-toolbar {
  background-color: transparent;
  .lg-icon {
    color: $black;
    &:hover {
      color: lighten($black, 30%);
    }
  }
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
  background-color: rgba(0,0,0,0.9);
  color: #999;
}

.lg-outer .lg-img-wrap {
  padding: 40px 5px;
}
