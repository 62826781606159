.klaro .cookie-modal {
  z-index: 11000 !important;
}

//.cookieconsent {
//  position: fixed;
//  top: auto !important;
//  bottom: 0;
//  left: 0;
//  right: 0;
//  background: rgba(0,0,0,.9);
//  color: white;
//  padding: 10px;
//  font-size: 16px;
//  z-index: 99999999 !important;
//  border-radius: 0;
//  border: none;
//  margin-bottom: 0;
//  p {
//    margin: 0;
//  }
//  a {
//    color: $white;
//    text-decoration: underline;
//    &:hover {
//      color: darken($white,15%);
//    }
//  }
//  .btn {
//    font-size: 0.8em;
//    background: transparent;
//    border-color: white;
//    color: white;
//    margin: 10px;
//    &:hover {
//      background: rgba(white,0.2);
//    }
//  }
//}
