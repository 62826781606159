.navbar {
	border: none;
	border-radius: 0;
	margin-bottom: 0;
	min-height: 0;
}

.navbar-toggle {
	padding: 0;
	margin: 0;
	border: 0;
	border-radius: 0;
}

.navbar-collapse {
	border-top: none;
	box-shadow: none;
}


.navbar-default {
	border-bottom: 1px solid $white;
	font-size: 20px;
	@include box-shadow(0 3px 5px 1px rgba(0,0,0,0.2));
	.navbar-header {
		padding: 15px 15px 18px 15px;
		.menu-icon {
			display: block;
			margin-top: 16px;
			width: 36px;//45px
			height: 26px;//33px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: 36px 26px;//45px 33px;
			cursor: pointer;
		}
		.menu-icon[aria-expanded="false"] {
			background-image: url(../images/svg/menu-icon.svg);
		}
		.menu-icon[aria-expanded="true"] {
			background-image: url(../images/svg/menu-icon-close.svg);
		}
		.company-logo {
			font-size: 0;
			a {
				display: inline-block;
				img {
					width: 40px;//70px
					height: 53px;//94px;
				}
			}
		}
	}
	.navbar-collapse {
		.navbar-nav {
			margin-top: 0;
			margin-bottom: 0;
			padding-top: 20px;
			padding-bottom: 22px;
			> li {
				a {
					text-transform: uppercase;
					letter-spacing: .1em;
					line-height: 1;
					padding: 0;
					margin: 17px 16px;
					display: inline-block;
					border-bottom: 1px solid transparent;
					&:hover {
						border-bottom: 1px solid $black;
					}
				}
			}
			> .active {
				a {
					border-bottom: 1px solid $black;
				}
			}
		}
	}
}

.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    max-height: none;
}

.navbar-fixed-top {
	position: relative;
}


#navbar .dropdown-menu {
    li {
			padding-left: 20px;
			a {
				border-bottom: none;
				margin: 10px 16px;
			}
			&.active {
				a {
					color: $gray;
					background-color: transparent;
					&:hover {
						color: $black;
					}
				}
			}
		}
}

@media screen and (min-width: $screen-sm) {


	/*
	CENTER MENU (CM): SET DISPLAY INLINE-BLOCK ON UL-ELEMENT (.navbar-nav). AND TEXT-ALIGN CENTER ON (.navbar-collapse) ELEMENT:
	http://stackoverflow.com/questions/18839582/center-a-horizontal-css-menu
	*/


	.navbar-default {
		//font-size: 20px;
		@include box-shadow(0 0 0 0 rgba(0,0,0,0));
		.navbar-header {
			float: none;
			padding: 0;
			padding-top: 40px;
			padding-bottom: 18px;
			@include transition(all 0.25s ease-in-out);
			.company-logo {
				width: 100%;
				@include transition(all 0.25s ease-in-out);
				a {
					display: block;
					margin: 0 0 0 50%;
					width: 140px;//220px;
					height: 145px;//228px;
					position: relative;
					z-index: 999;
					@include transition(all 0.25s ease-in-out);
					img {
						margin-left: -70px;
						width: 140px;//220px;
						height: 145px;//228px;
						@include transition(all 0.25s ease-in-out);
					}
				}
			}
		}
		.navbar-collapse {
			padding-left: 0;
			padding-right: 0;
			text-align: center;//Here (CM)
			margin-top: 0;
			@include transition(all 0.25s ease-in-out);
			.navbar-nav {
				float: none;
				display: inline-block;//Here (CM)
				border-top: none;
				border-bottom: none;
				padding-top: 0;
				padding-bottom: 0;
				padding-top: 41px;
				padding-bottom: 30px;
				@include transition(all 0.25s ease-in-out);
				> li {
					a {
						margin: 0 45px;
						padding-bottom: 2px;
						@include transition(all 0.25s ease-in-out);
					}
				}
			}
		}
	}


	//Opaque Styles
	.navbar-default.opaque {
		border-bottom: 1px solid lighten($black,80%);
		.navbar-header {
			padding-top: 10px;
			.company-logo {
				width: 10%;
				a {
					display: block;
					margin: 0;
					width: 60px;//140px
					height: 62px;//145px
					img {
						margin-left: 0;
						width: 60px;//140px
						height: 62px;//145px
					}
				}
			}
		}
		.navbar-collapse {
			margin-top: -80px;
			.navbar-nav {
				padding-bottom: 22px;
				padding-top: 20px;
				padding-left: 0;
				> li {
					a {
						font-size: 16px;
					}
				}
			}
		}
	}

	#navbar .dropdown-menu {
		border: none;
		left: 31px;
		padding-top: 15px;
		padding-bottom: 10px;
		box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
		border-radius: 0;

    li {
			padding-left: 0;
			a {
				border-bottom: none;
				margin: 10px 16px;
				border-bottom: 1px solid rgba($black,0);
				&:hover {
					background: transparent;
					border-bottom: 1px solid $black;
				}
			}
		}
	}


	#navbar .dropdown:hover .dropdown-menu {
	    display: block;
	    //margin-top: 0; // remove the gap so it doesn't close
	 }

}


@media screen and (min-width: $screen-md) {



}

@media screen and (min-width: $screen-lg) {

}


.navbar-default .navbar-collapse .navbar-nav>li a { white-space: nowrap; }