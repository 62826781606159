.sib-form {
 padding: 0 !important;
 .sib-container--large {
  padding: 0 !important;
  .sib-form-block {
   padding: 0 !important;
  }
 }
}

.sib-form .entry__field {
 border: 2px solid $black !important;
 border-radius: 0 !important;
}

.sib-divider-form-block > div {
 border-color: $black !important;
}