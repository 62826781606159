.embed-container {
	position: relative;
	iframe {
		max-width: 100%;
	}
}


@media screen and (min-width: $screen-sm) {



}

@media screen and (min-width: $screen-md) {


}

@media screen and (min-width: $screen-lg) {

	.container-fluid {
		margin: 0 auto;
		max-width: $maxWidth;
	}

}
