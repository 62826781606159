.social-media {
  padding: 25px 0;
  text-align: center;
  ul {
    display: inline-block;
    padding-left: 0;
    margin: 0;
    list-style: none;
    overflow: hidden;
    li {
      margin-left: 30px;
      float: left;
      &:first-child {
        margin-left: 0;
      }
      a {
        line-height: 1;
        display: block;
        svg {
          width: 38px;
          height: 38px;
          fill: $black;
        }
        &:hover svg {
          fill: darken($blue,25%);
        }
      }
    }
  }
  &.header {
    display: none;
  }
}



@media screen and (min-width: $screen-md) {

  .social-media {
    ul {
      li {
        margin-left: 60px;
      }
    }
    &.header {
      display: block;
      float: right;
      padding: 0 20px 0 0;
      margin-top: -7px;
      ul {
        li {
          margin: 0 8px 0 0;
          svg {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }

  .opaque {
    .social-media.header {
      margin-top: 19px;
    }
  }

}
